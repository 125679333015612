<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        For each of the compounds listed below, write the appropriate acid-base equilibrium
        reactions and use them to determine whether each of the solutions would be acidic, basic, or
        neutral. Don't forget to include the phase of each species as well as the charge (if any).
      </p>

      <p class="mb-2">a) <chemical-latex content="CH3NH2" /></p>

      <chemical-notation-input
        v-model="inputs.CH3NH2rxn"
        dense
        :show-note="false"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <v-select
        v-model="inputs.pHCH3NH2soln"
        class="d-sm-inline-block mb-5"
        style="width: 160px"
        dense
        :items="acidityItems"
        item-text="text"
        item-value="value"
        placeholder="pH"
      />

      <p class="mb-2">b) <chemical-latex content="HF" /></p>

      <chemical-notation-input
        v-model="inputs.HFrxn"
        dense
        :show-note="false"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <v-select
        v-model="inputs.pHHFsoln"
        class="d-sm-inline-block mb-5"
        style="width: 160px"
        dense
        :items="acidityItems"
        item-text="text"
        item-value="value"
        placeholder="pH"
      />

      <p class="mb-2">c) <chemical-latex content="CH3NH3Br" /></p>

      <chemical-notation-input
        v-model="inputs.CH3NH3Brrxn"
        dense
        :show-note="false"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <v-select
        v-model="inputs.pHCH3NH3Brsoln"
        class="d-sm-inline-block mb-5"
        style="width: 160px"
        dense
        :items="acidityItems"
        item-text="text"
        item-value="value"
        placeholder="pH"
      />

      <p class="mb-2">d) <chemical-latex content="NaF" /></p>

      <chemical-notation-input
        v-model="inputs.NaFrxn"
        dense
        :show-note="false"
        class="mb-3"
        :disabled="!allowEditing"
      />

      <v-select
        v-model="inputs.pHNaFsoln"
        class="d-sm-inline-block mb-0"
        style="width: 160px"
        dense
        :items="acidityItems"
        item-text="text"
        item-value="value"
        placeholder="pH"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'Question468',
  components: {
    NumberValue,
    ChemicalNotationInput,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        CH3NH2rxn: null,
        pHCH3NH2soln: null,
        HFrxn: null,
        pHHFsoln: null,
        CH3NH3Brrxn: null,
        pHCH3NH3Brsoln: null,
        NaFrxn: null,
        pHNaFsoln: null,
      },
      acidityItems: [
        {text: 'Acidic', value: 'acidic'},
        {text: 'Basic', value: 'basic'},
        {text: 'Neutral', value: 'neutral'},
      ],
    };
  },
};
</script>
